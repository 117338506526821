import React, { useEffect, useState } from "react";
import styles from "./ResetPassword.module.scss";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import Button from "../../../components/Buttons/Button/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PWD_RULES } from "../../../Util/ValidationUtil";
import ValidationHelper from "../../../components/ValidationHelper/ValidationHelper";
import { API_RESET_PASSWORD } from "../../../services/webServices";
import axiosClient from "../../../services/axios";
import Alert from "../../../components/Alert/Alert";
import Celebration from "../../../components/Icons/Celebration/Celebration";

const parentStyleContainer = {
  backgroundColor: "#fff",
  // border: "1px solid #e3e3e3",
  borderRadius: "16px",
  boxSizing: "border-box",
  fontFamily: "Source Sans Pro",
  height: "100%",
  // padding: "48px",
  left: "0",
  position: "relative",
  top: "0",
  textAlign: "left",
  width: "100%",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(":location >>>>", location);
  const [state, setState] = useState({
    loading: false,
    pwdRules: PWD_RULES,
    email: location?.state?.email,
    resetToken: "",
    newPassword: "",
    error: undefined,
    resetToken: "",
    password: "",
    displayEmailMsg: true,
    inputStage: "reset-password",
  });
  // console.log(":state >>>>", state);

  const passwordIsValid = () => {
    return state.pwdRules.filter((rule) => rule.valid !== true).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    if (state.resetToken.length === 0) {
      setState({
        ...state,
        error: {
          msg: "Please enter your reset Token",
          placement: "resetToken",
        },
        loading: false,
      });
      return;
    }

    if (!passwordIsValid()) {
      setState({
        ...state,
        error: {
          msg: "Please check password rules",
          placement: "password",
        },
        loading: false,
      });
      return;
    }
    // console.log("handle submit success >>>");

    try {
      const body = {
        resetToken: state.resetToken,
        newPassword: state.newPassword,
      };

      const response = await axiosClient.post(API_RESET_PASSWORD, body);
      if (response) {
        // console.log("response from API_RESET_PASSWORD >>", response);
        setState({
          ...state,
          loading: false,
          error: undefined,
          inputStage: "password-update-success",
        });

        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    } catch (error) {
      // console.log("error from API_RESET_PASSWORD >>>>", error);
      if (error?.response?.status === 401) {
        setState({
          ...state,
          loading: false,
          error: {
            placement: "TOKEN_EXPIRED",
            // msg: error?.response?.data?.message,
            msg: "Sorry, looks like the reset password link has expired or is incorrect. Please try again.",
          },
        });
        setTimeout(() => {
          navigate("/forgot-password");
        }, 2000);
      } else {
        setState({
          ...state,
          loading: false,
          error: {
            // placement: "general",
            // msg: "Something went wrong, please try again later.",
            msg: "Sorry, looks like the reset password link has expired or is incorrect. Please try again.",
            placement: "general",
          },
        });
      }
    }
  };
  const handleChangePassword = (value) => {
    const pwdRules = state.pwdRules.map((rule) => {
      rule.valid = rule.regexp.test(value);
      return rule;
    });

    setState({ ...state, pwdRules: pwdRules, newPassword: value });
  };

  useEffect(() => {
    if (!location?.state?.email) {
      navigate("/forgot-password");
    }
    setTimeout(() => {
      setState({ ...state, displayEmailMsg: false });
    }, 5000);
  }, []);

  const inputForm = () => {
    switch (state.inputStage) {
      case "reset-password":
        return (
          <ResetPasswordForm
            onSubmit={handleSubmit}
            error={state.error}
            email={state.email}
            setEmail={(value) => setState({ ...state, email: value })}
            resetToken={state.resetToken}
            setResetToken={(value) => setState({ ...state, resetToken: value })}
            password={state.newPassword}
            setPassword={handleChangePassword}
            validCheck={passwordIsValid()}
          />
        );

      case "password-update-success":
        return (
          <div>
            <div className={styles.celebration_wrapper}>
              <Celebration />
            </div>

            <div style={{ marginTop: "12px" }}>
              <Alert
                msg={"Success! Your password has been updated."}
                type="success"
              />
            </div>
          </div>
        );
    }
  };

  const ctaText = () => {
    switch (state.inputStage) {
      case "reset-password":
        return "Reset password";

      case "password-update-success":
        return "Password updated successfully";
    }
  };

  const header = () => {
    switch (state.inputStage) {
      case "reset-password":
        return "Reset password";

      case "password-update-success":
        return "Password updated successfully";
    }
  };

  return (
    <div className="auth-box-parent">
      <div className="auth-box-child">
        <div style={parentStyleContainer}>
          <div>
            <div>
              <h1 className={""}>{header()}</h1>
            </div>
            {state?.displayEmailMsg && (
              <Alert
                type="success"
                msg={`We've sent an email to ${state.email} with instructions.`}
              />
            )}
            <div className={"styles.pending_inner"}>
              {state.inputStage === "reset-password" && (
                <span className={"styles.instructions"}>
                  If the email doesn’t show up soon, check your spam folder. We
                  sent it from utility@DigitalCoverage.com.pk.
                </span>
              )}
              {inputForm()}
            </div>
            {state.error &&
              (state.error.placement === "general" ||
                state.error.placement === "TOKEN_EXPIRED") && (
                <Alert
                  type={state.error.variant ? state.error.variant : "danger"}
                  msg={state.error.msg}
                />
              )}

            {state.inputStage === "reset-password" && (
              <div className={styles.cta_bottom_box_button_reset}>
                <div>
                  <Button
                    fullwidth={true}
                    variant="primary"
                    onClick={(e) => {
                      setState({ ...state, error: undefined });
                      handleSubmit(e);
                    }}
                    loading={state.loading}
                    disabled={
                      state.loading || !state.resetToken || !state.newPassword
                    }
                    text="Reset password"
                  />
                  <div>
                    <ValidationHelper
                      rules={state.pwdRules}
                      passMatch={true}
                      error={state.error?.placement === "password"}
                    />
                  </div>
                  <div className={styles.return_to_login_wrapper}>
                    <Link to="/">Return to login</Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;

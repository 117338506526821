import React from "react";
import { useState } from "react";
import LoginForm from "./LoginForm/LoginForm";
import SignupForm from "./SignupForm/SignupForm.jsx";
import Button from "../../components/Buttons/Button/Button.jsx";
import CheckBox from "../../components/CheckBox/CheckBox.jsx";
import ValidationHelper from "../../components/ValidationHelper/ValidationHelper.jsx";
import Alert from "../../components/Alert/Alert";
import {
  nameValid,
  PWD_RULES,
  validateEmail,
} from "../../Util/ValidationUtil.js";
import "./Auth.css";
import { Link } from "react-router-dom";
import SetNameForm from "./SetNameForm/SetNameForm";
import PendingVerify from "./PendingVerify/PendingVerify";
import {
  API_LOGIN,
  API_LOGIN_VERIFY,
  API_SIGNUP,
  API_SIGNUP_VERIFY,
} from "../../services/webServices";
import axiosClient from "../../services/axios";

const Auth = (props) => {
  const [state, setState] = useState({
    signup: props.signup,
    inputStage: props.signup ? "signup" : "login",
    // inputStage: "pending_verification",
    pwdRules: PWD_RULES,
    username: "",
    name: "",
    password: "",
    loading: false,
    first_name: "",
    last_name: "",
    emailConsent: true,
    error: "props.error",
    verification_resend_status: "none",
    // verification_resend_status: "none" || "loading" || "error" || "success",
    userData: {},
    otp: "",
  });
  // console.log("state >>>>", state);

  const passwordIsValid = () => {
    return state.pwdRules.filter((rule) => rule.valid !== true).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("handle Submit >>>>");
    handleSubmitInner();
  };

  const handleSubmitInner = async () => {
    setState({ ...state, loading: true });

    if (state.signup && state.name.length === 0) {
      setState({
        ...state,
        error: {
          placement: "name",
          msg: "Add a name that only contains letters - and no special characters.",
        },
        loading: false,
      });
      return;
    }
    if (state.username.length === 0) {
      setState({
        ...state,
        error: {
          msg: "Please enter an email",
          placement: "email",
        },
        loading: false,
      });
      return;
    }

    if (!validateEmail(state.username)) {
      setState({
        ...state,
        error: {
          msg: "Looks like you forgot something",
          placement: "email",
        },
        loading: false,
      });
      return;
    }

    if (state.signup) {
      if (!passwordIsValid()) {
        setState({
          ...state,
          error: {
            msg: "Please check password rules",
            placement: "password",
          },
          loading: false,
        });
        return;
      }
    }

    await submit();
  };

  const getOriginalUsername = async () => {
    console.log("origional name >>");
  };

  const checkVerification = async () => {
    if (
      state.original_username === undefined ||
      state.original_username.length === 0
    ) {
      setState({
        ...state,
        error: {
          placement: "general",
          msg: "Unknown error, please try again later.",
        },
      });
      return;
    }
  };
  const checkOtpVerification = (otp) => {
    if (!otp || otp === "" || otp === undefined || otp.length === 0) {
      setState({
        ...state,
        error: {
          msg: "Please enter Valid OTP",
          placement: "OTP",
        },
      });
      return false;
    }
    return true;
  };

  const submit = async () => {
    let data;
    // setState({
    //   ...state,
    //   // inputStage: "set_name",
    //   inputStage: "pending_verification",
    //   loading: false,
    //   error: undefined,
    // });

    if (state.inputStage === "signup") {
      // return;
      setState({
        ...state,
        loading: true,
      });
      try {
        const body = {
          name: state.name,
          email: state.username,
          password: state.password,
        };

        const response = await axiosClient.post(API_SIGNUP, body);
        // console.log("response from API_SIGNUP >>", response);
        if (response) {
          setState({
            ...state,
            inputStage: "pending_verification",
            loading: false,
            error: undefined,
          });
        }
      } catch (error) {
        console.log("error from signup >>>>", error);
        setState({
          ...state,
          inputStage: "signup",
          loading: false,
          error: {
            placement: "Signup",
            msg: "Registration faild, please try again later.",
          },
        });
      }
    } else if (state.inputStage === "pending_verification") {
      setState({
        ...state,
        loading: true,
      });
      if (checkOtpVerification(state.otp)) {
        try {
          const body = {
            email: state.username,
            otp: state.otp,
          };

          const response = await axiosClient.post(API_SIGNUP_VERIFY, body);
          // console.log("response from API_SIGNUP_VERIFY >>", response);
          if (response) {
            setState({
              ...state,
              loading: false,
              error: undefined,
            });
            localStorage.setItem("token", response.data?.data?.token);
            // navigate("/home");
            setTimeout(() => {
              props.setIsLogin(true);
            }, 100);
          }
        } catch (error) {
          console.log("error from API_SIGNUP_VERIFY >>>>", error);
          if (error?.response?.status === 400) {
            setState({
              ...state,
              inputStage: "pending_verification",
              loading: false,
              error: {
                placement: "Verification",
                msg: error?.response?.data?.message,
              },
            });
          } else {
            setState({
              ...state,
              inputStage: "pending_verification",
              loading: false,
              error: {
                placement: "Verification",
                msg: "Verification faild, please try again later.",
              },
            });
          }
        }
      }
    } else if (state.inputStage === "set_name") {
      if (!nameValid(state.first_name) || !nameValid(state.last_name)) {
        setState({ ...state, loading: false });

        if (!nameValid(state.first_name)) {
          setState({
            ...state,
            error: {
              placement: "first_name",
              msg: "Add a name that only contains letters - and no special characters.",
            },
          });
        } else if (!nameValid(state.last_name)) {
          setState({
            ...state,
            error: {
              placement: "last_name",
              msg: "Add a name that only contains letters - and no special characters.",
            },
          });
        }

        return; // Errors handled in the respective components
      }
    } else {
      // login
      if (state.inputStage === "login") {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });
        try {
          const body = {
            email: state.username,
            password: state.password,
          };

          const response = await axiosClient.post(API_LOGIN, body);
          // console.log("response from API_LOGIN >>", response);
          if (response) {
            setState({
              ...state,
              inputStage: "pending_verification_login",
              loading: false,
              error: undefined,
            });
          }
        } catch (error) {
          console.log("error from login >>>>", error);
          setState({
            ...state,
            inputStage: "login",
            loading: false,
            error: {
              placement: "Authentication",
              msg: "Authentication Failed. Invalid email or password.",
            },
          });
        }
      } else if (state.inputStage === "pending_verification_login") {
        // checkVerification();
        setState({
          ...state,
          loading: true,
          error: undefined,
        });
        console.log(
          "checkOtpVerification(state.otp) >>>",
          checkOtpVerification(state.otp)
        );
        // return;
        if (checkOtpVerification(state.otp)) {
          try {
            const body = {
              email: state.username,
              otp: state.otp,
            };

            const response = await axiosClient.post(API_LOGIN_VERIFY, body);
            // console.log("response from API_LOGIN_VERIFY >>", response);
            if (response) {
              setState({
                ...state,
                loading: false,
                error: undefined,
              });
              localStorage.setItem("token", response.data?.data?.token);
              // redirect to home page
              // navigate("/home");
              setTimeout(() => {
                props.setIsLogin(true);
              }, 100);
            }
          } catch (error) {
            console.log("error from login >>>>", error);
            if (error?.response?.status === 400) {
              setState({
                ...state,
                inputStage: "pending_verification_login",
                loading: false,
                error: {
                  placement: "Verification",
                  msg: error?.response?.data?.message,
                },
              });
            } else {
              setState({
                ...state,
                inputStage: "pending_verification_login",
                loading: false,
                error: {
                  placement: "Verification",
                  msg: "Verification faild, please try again later.",
                },
              });
            }
          }
        }
      }
    }
  };

  const handleChangePassword = (value) => {
    const pwdRules = state.pwdRules.map((rule) => {
      rule.valid = rule.regexp.test(value);
      return rule;
    });

    setState({ ...state, pwdRules: pwdRules, password: value });
  };
  const handleToggleSignup = (e) => {
    e.preventDefault();

    setState({
      ...state,
      signup: !state.signup,
      inputStage: state.signup ? "login" : "signup",
      error: undefined,
    });
  };

  const inputForm = () => {
    switch (state.inputStage) {
      case "login":
        return (
          <LoginForm
            onSubmit={handleSubmit}
            onToggleSignup={handleToggleSignup}
            error={state.error}
            username={state.username}
            password={state.password}
            setUsername={(value) => setState({ ...state, username: value })}
            setPassword={handleChangePassword}
          />
        );

      case "signup":
        return (
          <SignupForm
            onSubmit={handleSubmit}
            onToggleSignup={handleToggleSignup}
            error={state.error}
            username={state.username}
            name={state.name}
            password={state.password}
            validCheck={passwordIsValid()}
            setUsername={(value) => setState({ ...state, username: value })}
            setName={(value) => setState({ ...state, name: value })}
            setPassword={handleChangePassword}
          />
        );

      case "set_name":
        return (
          <SetNameForm
            onSubmit={handleSubmit}
            error={state.error}
            first_name={state.first_name}
            last_name={state.last_name}
            setFirstName={(value) => setState({ ...state, first_name: value })}
            setLastName={(value) => setState({ ...state, last_name: value })}
          />
        );

      case "pending_verification":
        switch (state.verification_resend_status) {
          case "none":
            return (
              <div className={"styles.pending_inner"}>
                <Alert
                  type="success"
                  msg={`We've sent an email to ${state.username} with instructions.`}
                />
                <span className={"styles.instructions"}>
                  If the email doesn’t show up soon, check your spam folder. We
                  sent it from utility@DigitalCoverage.com.pk.
                </span>
                <PendingVerify
                  onSubmit={handleSubmit}
                  error={state.error}
                  username={state.username}
                  otp={state.otp}
                  setOtp={(value) => setState({ ...state, otp: value })}
                />
              </div>
            );
          case "loading":
            return (
              <div className={"styles.pending_inner"}>
                <Alert type="success" msg={"Loading..."} />
              </div>
            );
          case "error":
            return <div className={"styles.pending_inner"}></div>;
          case "success":
            return (
              <div className={"styles.pending_inner"}>
                <Alert
                  type="success"
                  msg={`We've resent an email to ${state.username} with instructions. Please check your inbox and spam folder.`}
                />
                <span className={"styles.instructions"}>
                  We sent it from utility@digitalcoverage.com.pk
                </span>
              </div>
            );
        }
      case "pending_verification_login":
        switch (state.verification_resend_status) {
          case "none":
            return (
              <div className={"styles.pending_inner"}>
                <Alert
                  type="success"
                  msg={`We've sent an email to ${state.username} with instructions.`}
                />
                <span className={"styles.instructions"}>
                  If the email doesn’t show up soon, check your spam folder. We
                  sent it from utility@DigitalCoverage.com.pk.
                </span>
                <PendingVerify
                  onSubmit={handleSubmit}
                  error={state.error}
                  username={state.username}
                  otp={state.otp}
                  setOtp={(value) => setState({ ...state, otp: value })}
                />
              </div>
            );
          case "loading":
            return (
              <div className={"styles.pending_inner"}>
                <Alert type="success" msg={"Loading..."} />
              </div>
            );
          case "error":
            return <div className={"styles.pending_inner"}></div>;
          case "success":
            return (
              <div className={"styles.pending_inner"}>
                <Alert
                  type="success"
                  msg={`We've resent an email to ${state.username} with instructions. Please check your inbox and spam folder.`}
                />
                <span className={"styles.instructions"}>
                  We sent it from utility@digitalcoverage.com.pk
                </span>
              </div>
            );
        }

        break;
    }
  };

  const ctaText = () => {
    switch (state.inputStage) {
      case "login":
        return "Log in";

      case "signup":
        return "Sign up for free";

      case "set_name":
        return "Set Name";

      case "pending_verification":
        return "Verify";

      case "pending_verification_login":
        return "Verify";
      // return "Pending Verification";

      case "forgot_password":
        return "Send OTP";
    }
  };

  const header = () => {
    switch (state.inputStage) {
      case "login":
        return "Log in";

      case "signup":
        return "Sign up";

      case "set_name":
        return "Set Name";

      case "pending_verification":
        return "Pending Verification";

      case "pending_verification_login":
        return "Pending Verification";

      case "forgot_password":
        return "Forgot password";
    }
  };
  return (
    <div className="auth-box-parent">
      <div className="auth-box-child">
        <div>
          <div>
            <h1 className={""}>{header()}</h1>
          </div>
        </div>
        <div>{inputForm()}</div>
        {state.inputStage === "signup" && (
          <>
            <ValidationHelper
              rules={state.pwdRules}
              passMatch={true}
              error={state.error?.placement === "password"}
            />
            <CheckBox
              id="emailConsentCheckBox"
              name="emailConsentCheckBox"
              padding="15px 0 10px 0"
              checked={true}
            >
              Email me with news and updates
            </CheckBox>
          </>
        )}

        {state.error &&
          (state.error.placement === "general" ||
            state.error.placement === "Authentication" ||
            state.error.placement === "Verification" ||
            state.error.placement === "Signup" ||
            state.error.placement === "OTP") && (
            <Alert
              type={state.error.variant ? state.error.variant : "danger"}
              msg={state.error.msg}
            />
          )}

        {!state.signup && !state.error && (
          <div className={"styles.login_margin"}></div>
        )}
        <div className={"styles.cta_bottom_box"}>
          <div className={"styles.cta_bottom_box_button_login mt-2"}>
            <Button
              variant="primary"
              fullwidth={true}
              onClick={handleSubmit}
              loading={state.loading}
              text={ctaText()}
              disabled={state.loading}
            />
          </div>

          {state.inputStage === "signup" ? (
            <div className={"styles.terms"}>
              By clicking the "Sign up for free" button, you are creating an
              account, and agree to DigitalCoverage'{" "}
              <a
                href="https://www.digitalcoverage.com.pk/about"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.digitalcoverage.com.pk/about"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          ) : (
            ""
          )}

          {state.inputStage === "login" ? (
            <div className={"forgot-password"}>
              <Link
                to="/forgot-password"
                className="text-decoration-none text-black"
              >
                Forgot password?
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default Auth;

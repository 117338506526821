import React from "react";
import { Outlet, Link } from "react-router-dom";
import { removeTokenFromLocalStorage } from "../../Util/localStorage";

function Layout({ isLogin }) {
  const handleLogout = () => {
    removeTokenFromLocalStorage();
    return;
  };
  return (
    <React.Fragment>
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      {isLogin ? (
        <>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/nothing-here">Nothing Here</Link>
              </li>
              <li>
                <a href="/" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
          <hr />
        </>
      ) : null}
      <Outlet />
    </React.Fragment>
  );
}

export default Layout;

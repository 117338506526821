import React from "react";
import NameInput from "../../../components/Forms/Fields/NameInput/NameInput";

function SetNameForm({
  onSubmit,
  error,
  first_name,
  last_name,
  setFirstName,
  setLastName,
}) {
  return (
    <form onSubmit={onSubmit}>
      <NameInput
        onSubmit={onSubmit}
        value={first_name}
        setValue={setFirstName}
        label="First name"
        inputName="first_name"
        placeholder="Add your first name"
        error={error}
      />
      <NameInput
        onSubmit={onSubmit}
        value={last_name}
        setValue={setLastName}
        label="Last name"
        inputName="last_name"
        placeholder="Add your last name"
        error={error}
      />
    </form>
  );
}

export default SetNameForm;

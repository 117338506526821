import axios from "axios";
import { defaultServerURL } from "./webServices";
import { getUserFromLocalStorage } from "../Util/localStorage";

const axiosClient = axios.create({
  baseURL: defaultServerURL,
});

axiosClient.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user.token) {
    const token = user.token;
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    // console.log("axiosClient.interceptors >>", response);
    // Get the updated token from the response header
    const token = response.headers["new-token"];

    // console.log("token axiosClient.interceptors >>", token);
    // Update the token in localStorage
    if (token) {
      localStorage.setItem("token", token);
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error status is 401 (Unauthorized) and the original request has not been retried
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      // logoutUser();
    }

    return Promise.reject(error);
  }
);
export default axiosClient;

import React, { useState } from "react";
import EmailInput from "../../../components/Forms/Fields/EmailInput/EmailInput";
import PasswordInput from "../../../components/Forms/Fields/PasswordInput/PasswordInput";
import NameInput from "../../../components/Forms/Fields/NameInput/NameInput";

function SignupForm({
  onSubmit,
  onToggleSignup,
  error,
  username,
  name,
  password,
  setUsername,
  setName,
  setPassword,
  validCheck,
}) {
  const [notice, setNotice] = useState("");

  function handleSetName(value) {
    if(error?.placement === "name"){
      setNotice(error?.msg)
    }
    setName(value);
  }
  function handleSetEmail(value) {
    if (/[A-Z]/.test(value)) {
      setNotice(
        "That looked like uppercase; we switched to lowercase instead."
      );
    }

    const lowercased = value.toLowerCase();

    setUsername(lowercased);
  }

  return (
    <form onSubmit={onSubmit}>
      <NameInput
        label="Name"
        inputName="name"
        error={error}
        notice={notice}
        onSubmit={onSubmit}
        value={name}
        setValue={handleSetName}
      />
      <EmailInput
        label="Email"
        inputName="email"
        showLoginLink="signup"
        onToggleSignup={onToggleSignup}
        error={error}
        notice={notice}
        onSubmit={onSubmit}
        value={username}
        setValue={handleSetEmail}
      />

      <PasswordInput
        onSubmit={onSubmit}
        value={password}
        error={error}
        setValue={setPassword}
        validCheck={validCheck}
        label="Password"
        inputName="new-password"
      />
    </form>
  );
}

export default SignupForm;

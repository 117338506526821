import React, { useCallback, useEffect, useState } from "react";
import { Modal as BModal } from "react-bootstrap";

import "./Modal.scss";
import Button from "../Buttons/Button/Button";

const BREAKPOINTS = {
  xsmall: 575.98,
  small: 767.98,
  medium: 991.98,
  large: 1199.98,
};

const Modal = ({
  id,
  children,
  className,
  show,
  size,
  backdrop,
  closeButton,
  title,
  displayCancel,
  displayOk,
  cancelText,
  okText,
  okButtonVariant,
  cancelButtonVariant,
  okButtonType,
  cancelButtonType,
  preExtraButtons,
  extraButtons,
  postExtraButtons,
  disabled,
  loading,
  scrollable,
  forceFullScreenOnMedium,
  handleClose,
  handleOk,
  handleShow,
}) => {
  const [closeBtn, setCloseBtn] = useState(true);

  const [okType, setOkType] = useState("button");
  useEffect(() => {
    if (okButtonType !== undefined) {
      setOkType(okButtonType);
    }
  }, [okButtonType]);

  const [cancelType, setCancelType] = useState("button");
  useEffect(() => {
    if (cancelButtonType !== undefined) {
      setCancelType(cancelButtonType);
    }
  }, [cancelButtonType]);

  const [okVariant, setOkVariant] = useState("primary");
  useEffect(() => {
    if (okButtonVariant !== undefined) {
      setOkVariant(okButtonVariant);
    }
  }, [okButtonVariant]);

  const [cancelVariant, setCancelVariant] = useState("tertiary");
  useEffect(() => {
    if (cancelButtonVariant !== undefined) {
      setCancelVariant(cancelButtonVariant);
    }
  }, [cancelButtonVariant]);

  const [modalBackdrop, setModalBackdrop] = useState(true);
  useEffect(() => {
    if (backdrop !== undefined) {
      setModalBackdrop(backdrop);
    } else {
      setModalBackdrop(true);
    }
  }, [backdrop]);

  const [modalSize, setModalSize] = useState();
  useEffect(() => {
    setModalSize(size);
  }, [size]);

  const [headerTitle, setHeaderTitle] = useState("");
  useEffect(() => {
    if (title !== undefined) {
      setHeaderTitle(title);
    } else {
      setHeaderTitle("");
    }
  }, [title]);

  const [cancelBtnText, setCancelBtnText] = useState("Cancel");
  useEffect(() => {
    if (cancelText) {
      setCancelBtnText(cancelText);
    }
  }, [cancelText]);

  const [okBtnText, setOkBtnText] = useState("Ok");
  useEffect(() => {
    if (okText) {
      setOkBtnText(okText);
    }
  }, [okText]);

  const [isScrollable, setIsScrollable] = useState(true);
  useEffect(() => {
    if (scrollable !== undefined) {
      setIsScrollable(!!scrollable);
    }
  }, [scrollable]);

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    if (show !== undefined) {
      setDisplay(show);
    } else {
      setDisplay(false);
    }
  }, [show]);

  const [displayCancelBtn, setDisplayCancelBtn] = useState(true);
  useEffect(() => {
    if (displayCancel !== undefined) {
      setDisplayCancelBtn(displayCancel);
    } else {
      setDisplayCancelBtn(true);
    }
  }, [displayCancel]);

  const [displayOkBtn, setDisplayOkBtn] = useState(true);
  useEffect(() => {
    if (displayOk !== undefined) {
      setDisplayOkBtn(displayOk);
    } else {
      setDisplayOkBtn(true);
    }
  }, [displayOk]);

  function closeModal(e) {
    setDisplay(false);

    if (handleClose) {
      handleClose(e);
    }
  }

  function onClickOk(e) {
    if (handleOk) {
      handleOk(e);
    }
  }

  const checkIfFullScreen = useCallback(
    () =>
      window.innerWidth < BREAKPOINTS.xsmall ||
      (forceFullScreenOnMedium && window.innerWidth < BREAKPOINTS.small),
    [forceFullScreenOnMedium]
  );

  const setCloseButtonValue = useCallback(() => {
    if (closeButton !== undefined) {
      setCloseBtn(checkIfFullScreen() ? false : !!closeButton);
    } else {
      setCloseBtn(!checkIfFullScreen());
    }
  }, [checkIfFullScreen, closeButton]);

  useEffect(() => {
    setCloseButtonValue();
  }, [closeButton, setCloseButtonValue]);

  useEffect(() => {
    window.addEventListener("resize", setCloseButtonValue);

    return () => {
      window.removeEventListener("resize", setCloseButtonValue);
    };
  }, [setCloseButtonValue]);

  return (
    <BModal
      id={id}
      show={display}
      onHide={closeModal}
      onShow={handleShow}
      size={modalSize}
      className={`w3s-modal-modal${
        forceFullScreenOnMedium ? " w3s-modal-fullscreen-md" : ""
      }${className ? ` ${className}` : ""}`}
      animation={false}
      scrollable={isScrollable}
      backdrop={checkIfFullScreen() ? "static" : modalBackdrop}
      centered={!checkIfFullScreen()}
    >
      {checkIfFullScreen() && (
        <div className="modal-top-row">
          {displayOkBtn && (
            <div className="modal-top-ok-group">
              <div
                className={
                  "modal-ok-alternative" +
                  ` modal-variant-${
                    okVariant === "primary" || okVariant === "destructive"
                      ? okVariant
                      : "primary"
                  }` +
                  `${!!disabled || !!loading ? " disabled" : ""}`
                }
                onClick={onClickOk}
              >
                {okText}
              </div>
            </div>
          )}
        </div>
      )}
      <BModal.Header closeButton={closeBtn && !loading}>
        <BModal.Title>{headerTitle}</BModal.Title>
      </BModal.Header>
      <BModal.Body>{children}</BModal.Body>
      <BModal.Footer>
        {!!preExtraButtons && (
          <div className="modal-extra-buttons justify-content-start modal-full-width">
            {preExtraButtons}
          </div>
        )}

        {displayCancelBtn && (
          <div className="modal-cancel-button">
            <Button
              variant={cancelVariant}
              as={cancelType}
              disabled={!!loading}
              text={cancelBtnText}
              onClick={closeModal}
            />
          </div>
        )}

        {!!extraButtons && (
          <div className="modal-extra-buttons">{extraButtons}</div>
        )}

        {displayOkBtn && (
          <div className="modal-ok-button">
            <Button
              variant={okVariant}
              as={okType}
              disabled={!!disabled || !!loading}
              text={okBtnText}
              loading={loading}
              onClick={onClickOk}
            />
          </div>
        )}

        {!!postExtraButtons && (
          <div className="modal-extra-buttons justify-content-end">
            {postExtraButtons}
          </div>
        )}
      </BModal.Footer>
    </BModal>
  );
};

Modal.defaultProps = {
  id: undefined,
  className: undefined,
  size: undefined,
  backdrop: true,
  closeButton: true,
  title: "",
  displayCancel: true,
  displayOk: true,
  cancelText: "Cancel",
  okText: "Ok",
  okButtonVariant: "primary",
  cancelButtonVariant: "tertiary",
  okButtonType: "button",
  cancelButtonType: "button",
  preExtraButtons: undefined,
  extraButtons: undefined,
  postExtraButtons: undefined,
  disabled: false,
  loading: false,
  scrollable: false,
  forceFullScreenOnMedium: false,
  handleClose: undefined,
  handleOk: undefined,
  handleShow: undefined,
};

export default Modal;

import React from "react";
import EmailInput from "../../../components/Forms/Fields/EmailInput/EmailInput";
import NameInput from "../../../components/Forms/Fields/NameInput/NameInput";

function PendingVerify({ onSubmit, error, username, otp, setOtp }) {
  return (
    <form onSubmit={onSubmit}>
      <EmailInput
        value={username}
        label="Email"
        inputName="email"
        disabled={true}
      />
      <NameInput
        onSubmit={onSubmit}
        value={otp}
        setValue={setOtp}
        label="OTP"
        inputName="otp"
        placeholder="Enter otp code"
        error={error}
      />
      <div style={{ height: "39px" }} />
    </form>
  );
}
export default PendingVerify;

export const localServer = "http://localhost:5000";
export const liveServerURL = "https://utility.digitalcoverage.com.pk";

export const defaultServerURL = liveServerURL;
export const API = "/api";

//Auth
export const API_SIGNUP = `${API}/auth/signup`;
export const API_SIGNUP_VERIFY = `${API}/auth/signup/verify`;
export const API_LOGIN = `${API}/auth/login`;
export const API_LOGIN_VERIFY = `${API}/auth/login/verify`;
export const API_FORGOT_PASSWORD = `${API}/auth/forgot-password`;
export const API_RESET_PASSWORD = `${API}/auth/reset-password`;

//User
export const API_GET_USERS = `${API}/user`;

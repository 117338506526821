export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};
export const removeTokenFromLocalStorage = () => {
  localStorage.removeItem("token");
};

export const getUserFromLocalStorage = () => {
  const result = {
    user: localStorage.getItem("user"),
    token: localStorage.getItem("token"),
  };
  const user = result.user ? JSON.parse(result.user) : null;
  const token = result.token ? result.token : null;
  // return user;
  return {
    user,
    token,
  };
};

import React from "react";
import styles from "./NameInput.module.scss";
import { nameValid } from "../../../../Util/ValidationUtil";
import Check from "../../../Icons/Check/Check";
import DangerExclamation from "../../../Icons/DangerExlamation/DangerExclamation";

function NameInput({
  onSubmit,
  value,
  setValue,
  label,
  inputName,
  placeholder,
  error,
  notice,
}) {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit(event);
    }
  };

  function checkNameValid() {
    return nameValid(value);
  }

  return (
    <div className={styles.input_wrapper + " -fif_wrp"}>
      <div className={styles.label_wrapper + " -fif_label_wrp"}>
        <label htmlFor={inputName}>{label}</label>
      </div>
      <div
        className={
          styles.input_field_wrapper +
          " -fif_input_wrp " +
          (error !== undefined ? styles.error : ``)
        }
      >
        <input
          //   className={styles.input_field + ' ' + (errorState() ? styles.invalid : styles.valid)}
          className={styles.input_field + " " + styles.valid}
          onSubmit={onSubmit}
          onKeyPress={handleKeyDown}
          type="text"
          name={inputName}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder ? placeholder : ""}
          id={"modal_" + inputName}
          autoCapitalize="none"
          autoCorrect="false"
        />
        {(error === undefined || error.placement !== "name") &&
        checkNameValid() ? (
          <Check />
        ) : (
          ``
        )}
        {error !== undefined && error.placement === "name" ? (
          <DangerExclamation size={16} fill="red" />
        ) : (
          ``
        )}
      </div>
      {error && error.placement === "name" ? (
        <span className={styles.name_error}>{error.msg}</span>
      ) : (
        ``
      )}
      {notice !== undefined && error === undefined ? (
        <span className={styles.name_notice}>{notice}</span>
      ) : (
        ``
      )}
      {error && error.placement === "resetToken" ? (
        <span className={styles.name_error}>{error.msg}</span>
      ) : (
        ``
      )}
      {notice !== undefined && error === undefined ? (
        <span className={styles.name_notice}>{notice}</span>
      ) : (
        ``
      )}
    </div>
  );
}
export default NameInput;

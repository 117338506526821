import React, { useState } from "react";
import styles from "./ForgotPassword.module.scss";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import Button from "../../../components/Buttons/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../../services/axios";
import { API_FORGOT_PASSWORD } from "../../../services/webServices";
import { validateEmail } from "../../../Util/ValidationUtil";

const parentStyleContainer = {
  backgroundColor: "#fff",
  // border: "1px solid #e3e3e3",
  borderRadius: "16px",
  boxSizing: "border-box",
  fontFamily: "Source Sans Pro",
  height: "100%",
  // padding: "48px",
  left: "0",
  position: "relative",
  top: "0",
  textAlign: "left",
  width: "100%",
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: false,
    email: "",
    error: undefined,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("handle submit >>>", state);
    setState({
      ...state,
      loading: true,
      error: undefined,
    });

    if (!validateEmail(state.email)) {
      setState({
        ...state,
        error: {
          msg: "Looks like you forgot something",
          placement: "email",
        },
        loading: false,
      });
      return;
    }

    try {
      const body = {
        email: state.email,
      };

      const response = await axiosClient.post(API_FORGOT_PASSWORD, body);
      // console.log("response from API_FORGOT_PASSWORD >>", response);
      if (response) {
        setState({
          ...state,
          loading: false,
          error: undefined,
        });
        // redirect to home page
        setTimeout(() => {
          navigate("/reset-password", { state: body });
        }, 100);
      }
    } catch (error) {
      // console.log("error from login >>>>", error);
      if (error?.response?.status === 400) {
        setState({
          ...state,
          loading: false,
          error: {
            placement: "Verification",
            msg: error?.response?.data?.message,
          },
        });
      } else {
        setState({
          ...state,
          loading: false,
          error: {
            placement: "Verification",
            msg: "Verification faild, please try again later.",
          },
        });
      }
    }
  };

  return (
    <div className="auth-box-parent">
      <div className="auth-box-child">
        <div style={parentStyleContainer}>
          <div>
            <div>
              <h1>Forgot password? </h1>
            </div>
            <p className={styles.instructions}>
              Please enter you email address.
            </p>
            <div>
              <ForgotPasswordForm
                onSubmit={handleSubmit}
                email={state.email}
                setEmail={(value) => setState({ ...state, email: value })}
                error={state.error}
              />
            </div>
            {/* {state.error &&
              (state.error.placement === "general" ||
                state.error.placement === "OTP") && (
                <Alert
                  type={state.error.variant ? state.error.variant : "danger"}
                  msg={state.error.msg}
                />
              )} */}

            <div className={styles.cta_bottom_box_button_reset}>
              <div>
                <Button
                  fullwidth={true}
                  variant="primary"
                  onClick={(e) => handleSubmit(e)}
                  // onClick={(e) => {
                  //   setState({ ...state, error: undefined });
                  //   handleSubmit(e);
                  // }}
                  loading={state.loading}
                  disabled={!state.email}
                  text="Forgot password"
                />

                <div className={styles.return_to_login_wrapper}>
                  <Link to="/">Return to login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;

import { useEffect, useContext } from "react";
import axiosClient from "../services/axios";
import ModalContext from "../context/ModalContext";

const useResponseInterceptor = () => {
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    const interceptor = axiosClient.interceptors.response.use(
      (response) => {
        // Handle successful response
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          openModal();
        }
        // Handle other error cases
        return Promise.reject(error);
      }
    );

    return () => {
      axiosClient.interceptors.response.eject(interceptor);
    };
  }, [openModal]);
};

export default useResponseInterceptor;

import { useEffect, useState } from "react";
import axiosClient from "../../services/axios";
import { API_GET_USERS } from "../../services/webServices";

function Home() {
  const [users, setUsers] = useState([]);
  // console.table(users)
  // console.log("users >>>",users);
  const getUsers = async () => {
    try {
      const response = await axiosClient.get(API_GET_USERS);
      // console.log("response from API_GET_USERS >>", response);
      if (response) {
        setUsers(response.data?.data);
      }
    } catch (error) {
      console.log("error from getUsers >>>>", error);
      // if (error.response && error.response.status === 401) {
      //   setShowModal(true);
      // }
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}
export default Home;

import React from "react";
import EmailInput from "../../../components/Forms/Fields/EmailInput/EmailInput";
import PasswordInput from "../../../components/Forms/Fields/PasswordInput/PasswordInput";

function LoginForm({
  onSubmit,
  onToggleSignup,
  error,
  username,
  password,
  setUsername,
  setPassword,
}) {
  return (
    <form onSubmit={onSubmit}>
      <EmailInput
        onSubmit={onSubmit}
        value={username}
        setValue={setUsername}
        label="Email"
        inputName="email"
        showLoginLink="login"
        onToggleSignup={onToggleSignup}
        error={error}
      />

      <PasswordInput
        onSubmit={onSubmit}
        value={password}
        setValue={setPassword}
        label="Password"
        inputName="current-password"
        error={error}
      />
      <div style={{ height: "39px" }} />
    </form>
  );
}
export default LoginForm;

import React from "react";
import EmailInput from "../../../../components/Forms/Fields/EmailInput/EmailInput";
import PasswordInput from "../../../../components/Forms/Fields/PasswordInput/PasswordInput";
import NameInput from "../../../../components/Forms/Fields/NameInput/NameInput";

function ResetPasswordForm({
  onSubmit,
  email,
  setEmail,
  error,
  resetToken,
  password,
  setResetToken,
  setPassword,
  validCheck,
}) {
  return (
    <form onSubmit={onSubmit}>
      <div>
        <EmailInput
          onSubmit={onSubmit}
          value={email}
          setValue={setEmail}
          label="Email"
          inputName="email"
          showLoginLink="none"
          error={error}
          disabled={true}
        />
        <NameInput
          label="Reset Token"
          inputName="resetToken"
          error={error}
          onSubmit={onSubmit}
          value={resetToken}
          setValue={setResetToken}
        />

        <PasswordInput
          onSubmit={onSubmit}
          value={password}
          error={error}
          setValue={setPassword}
          validCheck={validCheck}
          label="Password"
          inputName="new-password"
        />
      </div>
    </form>
  );
}
export default ResetPasswordForm;

import React from "react";
import EmailInput from "../../../../components/Forms/Fields/EmailInput/EmailInput";

function ForgotPasswordForm({ onSubmit, email, setEmail, error }) {
  return (
    <form onSubmit={onSubmit}>
      <div>
        <EmailInput
          onSubmit={onSubmit}
          value={email}
          setValue={setEmail}
          label="Email"
          inputName="email"
          showLoginLink="none"
          error={error}
        />
      </div>
    </form>
  );
}
export default ForgotPasswordForm;

import { Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./pages/Layout/Layout";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Dashboard from "./pages/Dashboard/Dashboard";
import NoMatch from "./pages/NoMatch/NoMatch";
import React, { useContext, useEffect, useState } from "react";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Auth from "./pages/Auth/Auth";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import { getUserFromLocalStorage } from "./Util/localStorage";
import ModalContext from "./context/ModalContext";
import useResponseInterceptor from "./hook/useResponseInterceptor";
import { logoutUser } from "./Util";
import Modal from "./components/Modal/Modal";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const { showModal } = useContext(ModalContext);
  useResponseInterceptor();

  useEffect(() => {
    const user = getUserFromLocalStorage();
    const token = user.token;
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    // for session expired
  }, []);

  return (
    <React.Fragment>
      {isLogin ? (
        <div>
          <h1>Basic Example</h1>

          <p>
            This example demonstrates some of the core features Authentication &
            Authorization.
          </p>

          <Routes>
            <Route path="/" element={<Layout isLogin={isLogin} />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </div>
      ) : (
        <React.Fragment>
          <Routes>
            <Route path="/" element={<Layout isLogin={isLogin} />}>
              <Route
                index
                element={<Auth signup={false} setIsLogin={setIsLogin} />}
              />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Routes>
        </React.Fragment>
      )}
      {showModal && (
        <Modal
          loading={false}
          show={true}
          displayCancel={false}
          displayOk
          handleClose={logoutUser}
          handleOk={logoutUser}
          title="Logout"
        >
          Session expired. You have been logged out.
        </Modal>
      )}
    </React.Fragment>
  );
}

export default App;
